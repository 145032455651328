module.exports = new Promise((resolve, reject) => {
      let entryUrl = '/remote/rap-mfe/remoteEntry.js';
      let scope = 'rap';


      const throwError = (msg) => {
        return Object.assign(new Error(msg), {
          caller: 'p8p-mfe-shell',
          entryUrl: '/remote/rap-mfe/remoteEntry.js',
        });
      };
      
      if (window.consoleLS) {
        const getFromLS = function c(r){const t="mfeDictionaryOverrides",e=localStorage.getItem(t),o={entryUrl:r.entryUrl,scope:r.scope,port:r.port??7e3,editMode:!1,status:"default"};if(!e)return localStorage.setItem(t,JSON.stringify([o])),o;const s=JSON.parse(e),n=s.find(i=>i.scope===r.scope);return n||(s.push(o),localStorage.setItem(t,JSON.stringify(s)),o)}
        const lsConfig = getFromLS({"port":8000,"scope":"rap","entryUrl":"/remote/rap-mfe/remoteEntry.js"});

        entryUrl = lsConfig.entryUrl;
        scope = lsConfig.scope;
      }

      const script = document.createElement('script');
      script.src = entryUrl;
      script.onload = () => {
        const mod = window[scope];
        
        if (!mod) {
          return resolve({
            get: () => Promise.resolve(() => Promise.reject(throwError(scope + ' not found on window. Inspect the response from remoteEntry and ensure it looks correct.'))),
            init: () => undefined,
          });
        }
  
        return resolve({
          get: (request) => {
            return mod.get(request)
              .catch((e) => {
                console.error(e);
                return Promise.reject(throwError(e.message))
              });
          },
          init: (arg) => mod.init(arg),
        });
      }
  
      // This block only gets hit when the request for remoteEntry.js has a non-200 status
      script.onerror = () => {
        return resolve({
          get: () => Promise.resolve(() => Promise.reject(throwError('Failed to get remoteEntry'))),
          init: () => undefined,
        });
      }
      
      document.head.appendChild(script);
    })
  ;